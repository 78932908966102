import { Quote } from '../../types/quote';

const requestCache = new Map<string, Promise<Quote>>();

const requestQuote = async (ticker: string, jwt: string): Promise<Quote> => {
  const tickerSymbol = ticker.toLowerCase();
  if (requestCache.has(tickerSymbol)) {
    return requestCache.get(tickerSymbol);
  }
  const dataFetch: Promise<Quote> = new Promise(async (resolve, reject) => {
    const apiResp = await fetch(`/api/1/symbol/${tickerSymbol}`, {
      headers: { Authorization: jwt },
    });
    if (!apiResp.ok) {
      return reject();
    }
    const data = await apiResp.json();
    resolve(data);
  });
  requestCache.set(tickerSymbol, dataFetch);
  setTimeout(() => {
    requestCache.delete(tickerSymbol);
  }, 50000);
  return dataFetch;
};

export { requestQuote };
